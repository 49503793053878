.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}
form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1, h2, h3 {
  text-align: center;
  color: #333;
}
h1 {
  font-size: 1.8em;
}
label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}
.required::after {
  content: ' *';
  color: red;
}
input[type="text"], textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1.2em;
}
input[type="file"] {
  margin-bottom: 20px;
}
input[type="text"]::placeholder {
  color: lightgray;
}
textarea {
  height: 80px;
  resize: none;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.button-group button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.button-group button.selected {
  background-color: #007bff;
  color: #fff;
}
.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.checkbox-group input {
  margin-right: 10px;
  transform: scale(1.2);
  position: relative;
  top: -2px;
}
.consent-text {
  font-size: 14px;
  color: #777;
}
.height-group, .weight-group {
  display: flex;
  justify-content: space-between;
}
.height-group div, .weight-group div {
  flex: 1;
  margin-right: 10px;
}
.height-group div:last-child, .weight-group div:last-child {
  margin-right: 0;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
}
.checkbox-container .checkbox-group {
  align-items: center;
}
.radio-container {
  display: flex;
  flex-direction: column;
}
.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.radio-group input {
  margin-right: 10px;
  transform: scale(1.2);
  position: relative;
  top: -2px;
}
button[type="submit"] {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}
button[type="submit"]:hover {
  background-color: #218838;
}
.centered {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}
.photo-section {
  border: 2px solid lightblue;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.photo-section label {
  display: block;
  margin-bottom: 10px;
}
.photo-section input[type="file"] {
  margin: 10px auto;
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
#bmi-result {
  text-align: center;
  font-weight: bold;
}
.bmi-warning {
  text-align: center;
  color: red;
  font-weight: bold;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
